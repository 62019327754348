import Image from 'next/image';
import { event } from 'nextjs-google-analytics';


const FloatingButton = () => {

  const handleClick = () => {
    const link = "/product/mugu-folding-playmat?variant=457";

    event("button_click", {
      event_category: "Homepage-FloatingButton",
      event_label: link,
    })

    window.location.href = link;
  }

  return (
    <div className="fixed right-2 bottom-2 md:right-[25px] md:bottom-[25px] z-[30]">
      <Image
        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/shop.png"}
        alt="mugu shop icon"
        quality={100}
        width={500}
        height={625}
        className={`cursor-pointer h-auto w-20 md:w-[120px]`}
        onClick={handleClick}
      />
    </div>
  )
}

export default FloatingButton
